import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DecodeService } from 'app/evo/login/service/decode.service';
import { Competence } from 'app/evo/models/competence';
import { CompetenceList } from 'app/evo/models/competence-list';
import { CompetenceModules } from 'app/evo/models/competencemodules';
import { Module } from 'app/evo/models/module';
import { User } from 'app/evo/models/user';
import { FilterTreePipe } from 'app/evo/pipe/filter-tree.pipe';
import { ErrorService } from 'app/evo/services/error.service';
import { EvoCompetenceService } from 'app/evo/services/evo-competence.service';
import { EvoModuleService } from 'app/evo/services/evo-module.service';
import { EvoUserService } from 'app/evo/services/evo-user.service';
import { HelperService } from 'app/evo/services/helper.service';
import { InformationService } from 'app/evo/services/information.service';
import { ConfirmationService, ConfirmEventType, MenuItem, TreeDragDropService, TreeNode } from 'primeng/api';

declare var window: any;
declare var bootstrap:any;

const createNode = (label: string, key: string,parent:number,userRole:string,isExpanded:boolean): TreeNode => ({
  label,
  key,
  collapsedIcon: 'pi pi-folder', expandedIcon:'pi pi-folder-open',  
  children: [],
  data: {id:Number.parseInt(key),description:'',competenceLevelId:null,parentId:(parent==0||parent==null||parent==undefined)?null:parent},
  draggable: userRole === 'Admin' && key !== '0',
  droppable: userRole === 'Admin',
  expanded:isExpanded
});

const markLeafNodes = (node: TreeNode): void => {
  if (!node.children || node.children.length === 0) {
    node.leaf = true; // Mark node as leaf if it has no children
  } else {
    node.leaf = false; // Not a leaf since it has children
    node.children.forEach(child => markLeafNodes(child)); // Recursively mark child nodes
  }
};


@Component({
  selector: 'app-evo-competence-list',
  templateUrl: './evo-competence-list.component.html',
  styleUrls: ['./evo-competence-list.component.scss'
  ],
  providers:[ConfirmationService,TreeDragDropService,FilterTreePipe]
})
export class EvoCompetenceListComponent implements OnInit,OnDestroy {
  
  constructor(
    private cdref: ChangeDetectorRef,
    private competenceService: EvoCompetenceService,
    private userService: EvoUserService,
    private moduleService: EvoModuleService,

    private errorService: ErrorService,
    private helperService: HelperService,
    private informationService: InformationService,
    private decodeService: DecodeService,
    private router: Router,
    private confirmationService:ConfirmationService,
    private filterTreePipe:FilterTreePipe,
  ) {
    this.myId = this.decodeService.getUserId();
    this.userRole = this.decodeService.getRole();

    this.constructTreeStructure();

   }
  myId: number;
  userRole: string = "";
  filterText: string = "";
  filterMainText:string="";
  deletedCompetenceId: number = 0;
  public basicSelectedOption: number = 10;

  users: User[] = [];
  modules: any[] = [];

  subModules:CompetenceModules[]=[];
  competences: CompetenceList[] = [];

  selectedUsers:User[]=[];
  selectedSubModules:CompetenceModules[]=[];

  user: User = new User();
  competence: Competence = new Competence();
  expandedRows: any = {};

  competenceModules:CompetenceModules[]=[];

  private themeLink: HTMLLinkElement;
  private primengLink: HTMLLinkElement;
  private primeiconsLink: HTMLLinkElement;

  filteredCompetenceModules:CompetenceModules[]=[];
  filteredCompetences: CompetenceList[] = [];

  filterModules: any[] = [];
  selectedModules:TreeNode[]=[];

  filteredCompetenceMainTree:TreeNode[]=[];
  selectedMainModules:TreeNode[]=[];

  filteredCompetenceModuleTree:TreeNode[]=[];
  action:string="Ekle";

  competenceModuleTree:TreeNode[]=[createNode("Yetkinlikler","0",0,this.userRole,true)];
  competenceMainTree:TreeNode[]=[createNode("Yetkinlikler","0",0,this.userRole,true)];

  private scrollInterval: any;
  private scrollSpeed = 50; // Speed of scrolling
  private margin = 200; // Margin to start scrolling
  selectedNode: any;

  competenceBatchNamesString:string = "";
  competenceBatchNames:string[]=[];

  competenceRenamed:string = "";

  competenceLevels:any[]=[  
    { id: 1, label: "Çok az bilgi sahibi" },
    { id: 2, label: "Temel seviye bilgi sahibi" },
    { id: 3, label: "Bilgi sahibi ve gözetim altında işi yapabilir" },
    { id: 4, label: "Bilgi sahibi ve tek başına yapabilir" },
    { id: 5, label: "Eğitici ve öğretici düzeyde bilgi sahibi" }
  ]

  contextMenuItems : MenuItem[] = [
    {label: 'Yetkinlik Ekle', icon: 'pi pi-file', command: () => this.openCompetenceBatchModal() },
    {label: 'Adını Değiştir', icon: 'pi pi-pencil', command: () => this.openModuleRenameModal() },
    {label: 'Yetkinlik Sil', icon: 'pi pi-trash', command: () => this.deleteCompetenceModule() },

 ];

 nodeMap:Map<number, TreeNode> =  new Map<number, TreeNode>()

 updateNode:TreeNode = null;

 displayRenameCompetenceModal:boolean=false;
 displayAddCompetenceModal:boolean=false;
 
  resetNode(node: TreeNode): TreeNode {
    return {
      ...node,
      children: [], // Make sure the children are completely reset
    };
  }

  deleteMainTree(){
    this.competenceMainTree = [this.resetNode(createNode("Yetkinlikler", "0",0,this.userRole,true))];
    this.cdref.detectChanges();

  }

  deleteModuleTree(){
    this.competenceModuleTree = [this.resetNode(createNode("Yetkinlikler", "0",0,this.userRole,true))];
    this.cdref.detectChanges();

  }
  
  constructTreeStructure(){
    this.competenceService.getCompetenceModuleList().subscribe((res:any)=>{
      var competenceModules = res.data;
      

      competenceModules.forEach((sablon) => {
        this.nodeMap.set(sablon.id, createNode(sablon.name, sablon.id.toString(),sablon.parentId,this.userRole,false));
      });
    })
  }

  nodeDrop(event: any) {
    const dragNode = event.dragNode;
    const dropNode = event.dropNode;

    var competenceModule : CompetenceModules = {
      id: dragNode.data.id,
      name: dragNode.label,
      parentId: dropNode.data.id,
    };

    if(competenceModule.parentId == 0){
      competenceModule.parentId = null;
    }

    this.confirmationService.confirm({
      message: 'Bu yetkinlik modülünü değiştirmek istediğinize emin misiniz?',
      header: 'Yetkinlik Modül Değişimi',
      acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
      rejectButtonStyleClass: 'btn btn-secondary mr-2',
      acceptLabel: 'Evet',
      rejectLabel: 'Hayır',
      accept: () => {
        this.updateCompetenceModule(competenceModule);

      },
      reject: (type: ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;      
        }
      }
    });

  }

  ngOnDestroy(): void {
    this.unloadPrimeNGStyles();
    document.removeEventListener('dragover', this.onDragOver);
    document.removeEventListener('dragend', this.stopScrolling);
    this.stopScrolling();
  }

  ngOnInit(): void {
    this.loadPrimeNGStyles();

    document.addEventListener('dragover', this.onDragOver);
    document.addEventListener('dragend', this.stopScrolling);
    
    this.userService.getListForFilter().subscribe((items: any) => {
      this.users = items.data;
    });

    setTimeout(()=>{
      this.requiredData();
      this.list();
    },500)

  }

  requiredData(){
    this.competenceService.getCompetenceModuleList().subscribe((res:any)=>{
      this.deleteModuleTree();
      this.competenceModules = res.data;
      this.constructModuleTree(this.competenceModules);
    })
  }

  list() {
    if (this.userRole == "Admin") {
      this.competenceService.getList().subscribe((items: any) => {
        this.competences = items.data;
        this.filteredCompetences = this.competences;
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      },()=>{
        setTimeout(()=>{
          this.deleteMainTree();
          this.tableByFiltersId();
        },500)

      });
    }
    else {
      this.competenceService.getListByUserId(this.myId).subscribe((items: any) => {
        this.competences = items.data;
        this.filteredCompetences = this.competences;
        this.informationService.list(items.message)
      }, (err) => {
        this.errorService.errorHandler(err);
      },()=>{
        setTimeout(()=>{
          this.deleteMainTree();
          this.tableByFiltersId();
        },500)

      });
    }
  }

  handleSelectionChange(event?: any) {
    setTimeout(() => { // Ensure the DOM is updated
      const selectedItems = document.querySelectorAll('.p-multiselect-token .p-multiselect-token-label');
      selectedItems.forEach((item: HTMLElement) => {
        const text = item.textContent;
        if (text.length > 10) {
          const trimmedText = text.substring(0, 10) + '...';
          item.textContent = trimmedText;
        }
      });
    }, 0);
  }


  constructModuleTree(competenceModuleList:any[]){

    competenceModuleList.forEach((sablon) => {
      
      const node = {...this.nodeMap.get(sablon.id)};
      if (sablon.parentId !=null) {
        const parentNode = { ...this.nodeMap.get(sablon.parentId) };
        if (parentNode && !parentNode.children.some(child => child.key === node.key)) {
          parentNode.children.push(node);
        }
      } else {
        this.competenceModuleTree[0].children.push(node);
      }
    });
    this.filteredCompetenceModuleTree = this.competenceModuleTree
    this.filterModules = JSON.parse(JSON.stringify(this.competenceModuleTree[0].children));
    this.filterCompetenceModuleTree();
  }

  filterCompetenceModuleTree(){
    const labels: string[] = (this.selectedModules || []).map(module => module.label);
    this.filteredCompetenceModuleTree = this.filterTreePipe.transform(this.competenceModuleTree, labels);
    if(this.selectedNode!=null){
      this.expandParents(this.selectedNode, this.filteredCompetenceModuleTree);
    }

  }

  filterCompetenceMainTree(){
    const labels: string[] = (this.selectedMainModules || []).map(module => module.label);
    this.filteredCompetenceMainTree = this.filterTreePipe.transform(this.competenceMainTree, labels);

    if(this.updateNode!=null){
      this.expandParents(this.updateNode, this.filteredCompetenceMainTree);
    }

  }
  
  getParents(node: TreeNode, tree: TreeNode[]): TreeNode | null {
    let currentNode = { ...node, children: [] }; // Clone the current node with empty children
    let rootNode: TreeNode | null = currentNode;
  
    while (currentNode.data.parentId) {
        const parentNode = this.nodeMap.get(currentNode.data.parentId);
  
        if (parentNode) {
            const existingParent = this.findParentInTree(tree, parentNode.key);
            if (existingParent) {
                // Parent exists, just return the parent
                existingParent.children.push(currentNode);
                return existingParent;
            } else {
                // Clone parent and continue
                const parentClone = { ...parentNode, children: [] };
                parentClone.children.push(currentNode);
                currentNode = parentClone;
                rootNode = parentClone;
            }
        } else {
            break;
        }
    }
  
    return rootNode;
  }

  constructMainTree(competenceList: any[]) {
    const filteredTree: TreeNode[] = [];

    competenceList.forEach((competence: any) => {
        const originalNode = { ...this.nodeMap.get(competence.moduleId) };
      
        if (originalNode) {
            const node = { 
                ...originalNode, 
                data: { ...competence, parentId: originalNode.data.parentId }, 
                children: [] 
            };
  
            const parentNode = this.getParents(node, filteredTree);
            if (parentNode) {
                if (!filteredTree.includes(parentNode)) {
                    filteredTree.push(parentNode);
                }
            } else {
                filteredTree.push(node);
            }
        }
    });


    this.competenceMainTree[0].children = this.organizeTree(filteredTree);

    this.competenceMainTree.forEach(node => markLeafNodes(node));

    this.filteredCompetenceMainTree = this.competenceMainTree;
    this.filterCompetenceMainTree();
    
  }



  findParentInTree(tree: TreeNode[], parentKey: string): TreeNode | null {
      for (let node of tree) {
          if (node.key === parentKey) {
              return node;  // Parent found
          }
          // Recursively search in children
          const found = this.findParentInTree(node.children || [], parentKey);
          if (found) {
              return found;
          }
      }
      return null;  // Parent not found
  }

  organizeTree(treeNodes: TreeNode[]): TreeNode[] {
    const treeMap: { [key: string]: TreeNode } = {};
    const rootNodes: TreeNode[] = [];

    // First, build a map of nodes by their key for quick access
    treeNodes.forEach(node => {
        treeMap[node.key] = node;
    });

    treeNodes.forEach(node => {
        if (node.data.parentId) {
            // If node has a parentId, find its parent in the map
            const parentNode = treeMap[node.data.parentId];

            if (parentNode) {
                // Ensure the parent has a children array
                parentNode.children = parentNode.children || [];

                // Check if the node is already a child of its parent
                if (!parentNode.children.some(child => child.key === node.key)) {
                    parentNode.children.push(node); // Add the node to its parent
                }
            }
        } else {
            // If no parentId, it's a root node, add to root nodes
            rootNodes.push(node);
        }
    });

    // Return only the nodes that do not have a parent (i.e., root nodes)
    return rootNodes;
  }

  get(id: number) {
    this.selectedModules = [];
    this.competenceService.get(id).subscribe((item: any) => {
      this.competence = item.data;
      this.user.id = this.competence.userId;
      var node : TreeNode = this.nodeMap.get(this.competence.moduleId);
      if (node) {
        node.data = {...this.competence,parentId:node.data.parentId};
        this.updateNode = JSON.parse(JSON.stringify(node));
        this.filteredCompetenceModuleTree = []
        this.filteredCompetenceModuleTree.push(node);
        this.cdref.detectChanges();

      }

    });
  }

  expandParents(node: TreeNode, tree: TreeNode[]) {
    if(node.data.parentId){
      const parentNode = this.findParentInTree(tree, node.data.parentId.toString());
      console.log(parentNode);
      if (parentNode) {
          parentNode.expanded = true;  // Expand the parent node
          this.expandParents(parentNode, tree);  // Recursively expand its parents
      }
    }
    else{
      const parentNode = this.findParentInTree(tree, node.data.id.toString());
      if (parentNode) {
        parentNode.expanded = true;  // Expand the parent node
      }
    }
  }


  deleteNodeWithFilter(nodeKey: string, tree: TreeNode[]): void {
    for (let node of tree) {
      if (node.children && node.children.length) {
        node.children = node.children.filter(child => child.key !== nodeKey);
  
        this.deleteNodeWithFilter(nodeKey, node.children);
      }
    }
  }

  onNodeRightClick(event: any) {
    this.selectedNode = event.node;  // Set the selected node
  }

  clear(){
    this.updateNode = null;
    this.selectedNode = null;
    this.competence = new Competence();

    this.requiredData();
    this.cdref.detectChanges();

  }

  saveCompetence(node:any){
    var competence : Competence={
      id:this.competence.id,
      userId: this.userRole == "Admin" ? this.user.id : this.myId,    
      competenceLevelId:node.data.competenceLevelId,
      description:node.data.description,
      moduleId: Number.parseInt(node.key),

    }

    if(this.checkCompetenceValidation()==true){
      if(competence.id==0){
        this.addCompetence(competence);
      }
      else{
        this.confirmationService.confirm({
          message: 'Bu yetkinliği değiştirmek istediğinize emin misiniz?',
          header: 'Yetkinlik Değişimi',
          acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
          rejectButtonStyleClass: 'btn btn-secondary mr-2',
          acceptLabel: 'Evet',
          rejectLabel: 'Hayır',
          accept: () => {
            this.updateCompetence(competence);
          },
          reject: (type: ConfirmEventType) => {
            switch(type) {
              case ConfirmEventType.REJECT:
                break;
              case ConfirmEventType.CANCEL:
                break;      
            }
          }
        });
      }
    }
  }

  addCompetence(item:any){

    this.competenceService.add(item).subscribe((items: any) => {

      this.informationService.list(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 150);
    });

  }

  updateCompetence(item:any){

    this.competenceService.update(item).subscribe((items: any) => {

      this.informationService.update(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      setTimeout(() => {
        this.list();
      }, 150);
    });

  }

  openModuleRenameModal(){
      var formModal = new window.bootstrap.Modal(document.getElementById('renameCompetenceModal'))
      formModal.show();
  }

  renameCompetenceModule(){
    var competenceModule :CompetenceModules = {
      id: this.selectedNode.key,
      name: this.competenceRenamed,
      parentId: this.selectedNode.data.parentId
    }

    this.updateCompetenceModule(competenceModule);
  }

  openCompetenceBatchModal(){
      var formModal = new window.bootstrap.Modal(document.getElementById('addCompetenceModal'))
      formModal.show();
  }

  closeAllModalsAndOpen(modalToOpenId) {
    // Find all open modals (modals that have the 'show' class)
    const openModals = document.querySelectorAll('.modal.show');

    // Loop through all open modals and close them
    openModals.forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);  // Get Bootstrap modal instance
        if (modalInstance) {
            modalInstance.hide();  // Close the modal
        }
    });

    // Wait 500ms, then open the desired modal
    setTimeout(() => {
        const modalToOpenElement = document.getElementById(modalToOpenId);
        if (modalToOpenElement) {
            const formModal = new bootstrap.Modal(modalToOpenElement);
            formModal.show();  // Open the modal
        }
    }, 500);  // Delay of 500ms
  }

  addCompetenceBatch(){

    var moduleId = this.selectedNode.data.id
    this.competenceBatchNames = this.competenceBatchNamesString.split("\n");
    var competenceModuleList : CompetenceModules[] = [];

    if(moduleId == 0){
      moduleId = null;
    }
    this.competenceBatchNames.forEach((competenceName:string)=>{
      var competenceModule : CompetenceModules = {
        id: 0,
        name: competenceName,
        parentId: moduleId
      }
      competenceModuleList = competenceModuleList.concat(competenceModule);
    })

    this.competenceService.addCompetenceModuleInBulk(competenceModuleList).subscribe((res:any)=>{
    },(err)=>{
      this.errorService.errorHandler(err);
    },()=>{
      this.constructTreeStructure();
      setTimeout(() => {
        this.requiredData();
        this.list();
        this.closeAllModalsAndOpen("addcompetence");
      }, 150);
    })
  }

  updateCompetenceModule(item:any){

    this.competenceService.updateCompetenceModule(item).subscribe((items: any) => {


      this.informationService.update(items.message)
    }, (err) => {
      this.errorService.errorHandler(err);
    }, () => {
      this.constructTreeStructure();
      setTimeout(() => {
        this.requiredData();
        this.list();
        this.closeAllModalsAndOpen("addcompetence");

      }, 150);
    });
  }

  deleteCompetenceModule(){

    this.confirmationService.confirm({
      message: 'Bu yetkinlik modülünü silmek istediğinize emin misiniz?',
      header: 'Yetkinlik Silme',
      acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
      rejectButtonStyleClass: 'btn btn-secondary mr-2',
      acceptLabel: 'Evet',
      rejectLabel: 'Hayır',
      accept: () => {
        this.competenceService.deleteCompetenceModule(Number.parseInt(this.selectedNode.key)).subscribe((items: any) => {
          this.informationService.delete(items.message)
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          this.constructTreeStructure();
          setTimeout(() => {
            this.requiredData();
            this.list();   
          }, 150);
        });
      },
      reject: (type: ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;      
        }
      }
    });


  }

  closeAllModalsExcept() {

  }

  loadPrimeNGStyles() {
    const head = document.getElementsByTagName('head')[0];

    // PrimeNG theme for version 14
    this.themeLink = document.createElement('link');
    this.themeLink.rel = 'stylesheet';
    this.themeLink.href = 'https://cdn.jsdelivr.net/npm/primeng@14/resources/themes/lara-light-blue/theme.css'; // Specify PrimeNG 14
    head.appendChild(this.themeLink);
    
    // PrimeNG core styles for version 14
    this.primengLink = document.createElement('link');
    this.primengLink.rel = 'stylesheet';
    this.primengLink.href = 'https://cdn.jsdelivr.net/npm/primeng@14/resources/primeng.min.css'; // Specify PrimeNG 14
    head.appendChild(this.primengLink);
    
    // PrimeIcons
    this.primeiconsLink = document.createElement('link');
    this.primeiconsLink.rel = 'stylesheet';
    this.primeiconsLink.href = 'https://cdn.jsdelivr.net/npm/primeicons/primeicons.css';
    head.appendChild(this.primeiconsLink);
  }

  unloadPrimeNGStyles() {
    if (this.themeLink) {
      this.themeLink.remove();
    }
    if (this.primengLink) {
      this.primengLink.remove();
    }
    if (this.primeiconsLink) {
      this.primeiconsLink.remove();
    }
  }

  delete(id: number) {

    this.confirmationService.confirm({
      message: 'Bu yetkinliği silmek istediğinize emin misiniz?',
      header: 'Yetkinlik Silme',
      acceptButtonStyleClass: 'btn btn-success hide-duplicated-icon',
      rejectButtonStyleClass: 'btn btn-secondary mr-2',
      acceptLabel: 'Evet',
      rejectLabel: 'Hayır',
      accept: () => {
        this.competenceService.delete(id).subscribe((items: any) => {
          this.informationService.delete(items.message);
        }, (err) => {
          this.errorService.errorHandler(err);
        }, () => {
          setTimeout(() => {
            this.list();          
          }, 150);
        });
      },
      reject: (type: ConfirmEventType) => {
        switch(type) {
          case ConfirmEventType.REJECT:
            break;
          case ConfirmEventType.CANCEL:
            break;      
        }
      }
    });

  }

  exportExcel() {
    let element = document.getElementById("excel-table");
    let title = "Yetkinlikler";
    this.helperService.exportExcel(element, title);
  }

  tableByFiltersId() {
  

      let filteredItems = this.competences as any;

      if (this.selectedUsers.length > 0) {
        filteredItems = filteredItems.filter(item => this.selectedUsers.includes(item.userId));
      }

      this.filteredCompetences = filteredItems;
      this.constructMainTree(this.filteredCompetences);

  }


  checkCompetenceValidation(){

    var validationCheck = true;
    if(this.userRole=='Admin'){
      if(this.user.id == 0 || this.user.id==null || this.user.id == undefined){
        this.informationService.delete("Kullanıcı seçilmemiştir.")
        validationCheck = false;
        return validationCheck;
      }
    }


    return validationCheck;
  }

  private onDragOver = (event: DragEvent): void => {
    const { clientY } = event;
    if (!clientY) return;

    const viewportHeight = window.innerHeight;

    if (clientY < this.margin) {
      this.startScrolling(-this.scrollSpeed);
    } else if (clientY > viewportHeight - this.margin) {
      this.startScrolling(this.scrollSpeed);
    } else {
      this.stopScrolling();
    }
  };

  private startScrolling = (scrollSpeed: number): void => {
    if (this.scrollInterval) clearInterval(this.scrollInterval);
    this.scrollInterval = setInterval(() => window.scrollBy(0, scrollSpeed), 50);
  };

  private stopScrolling = (): void => {
    if (this.scrollInterval) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }
  };
}
