import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from 'app/main/sample/home.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EvoDepartmentAddFormComponent } from './components/form/evo-department-add-form.component';
import { EvoDepartmentUpdateFormComponent } from './components/form/evo-department-update-form.component';
import { EvoOperationClaimAddFormComponent } from './components/form/evo-operationclaim-add-form.component';
import { EvoProjectAddFormComponent } from './components/form/evo-project-add-form.component';
import { EvoTicketAddFormComponent } from './components/form/evo-ticket-add-form.component';
import { EvoUserOperationClaimAddFromComponent } from './components/form/evo-useroperationclaim-add-form.component';
import { EvoDepartmentListComponent } from './components/list/evo-department-list.component';
import { EvoProjectListComponent } from './components/list/evo-project-list.component';
import { EvoTicketListComponent } from './components/list/evo-ticket-list.component';
import { EvoTicketMessagesListComponent } from './components/list/evo-ticketmessages-list.component';
import { EvoProjectUpdateFormComponent } from './components/form/evo-project-update-form.component';
import { EvoTicketPipe } from './pipe/evo-ticket.pipe';
import { EvoProjectPipe } from './pipe/evo-project.pipe';
import { EvoDepartmentPipe } from './pipe/evo-department.pipe';
import { EvoTicketuserPipe } from './pipe/evo-ticketuser.pipe';
import { FormsModule } from '@angular/forms';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { EvoHomeComponent } from './evo-home.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CoreCommonModule } from '@core/common.module';
import { ToastrModule } from 'ngx-toastr';
import { QuillModule } from 'ngx-quill';
import { EvoOperationClaimListComponent } from './components/list/evo-operationclaim-list.component';
import { EvoOperationClaimUpdateFormComponent } from './components/form/evo-operationclaim-update-form.component';
import { EvoOperationclaimPipe } from './pipe/evo-operationclaim.pipe';
import { EvoUserOperationClaimListComponent } from './components/list/evo-useroperationclaim-list.component';
import { EvoUserOperationClaimUpdateFormComponent } from './components/form/evo-useroperationclaim-update-form.component';
import { EvoUseroperationclaimInfoFormComponent } from './components/form/evo-useroperationclaim-info-form.component';
import { EvoUserOperationClaimPipe } from './pipe/evo-useroperationclaim.pipe';
import { EvoUserListComponent } from './components/list/evo-user-list.component';
import { EvoUserAddFormComponent } from './components/form/evo-user-add-form.component';
import { EvoUserUpdateFormComponent } from './components/form/evo-user-update-form.component';
import { EvoUserPipe } from './pipe/evo-user.pipe';
import { EvoPartnerPipe } from './pipe/evo-customer.pipe';
import { EvoPartnerListComponent } from './components/list/evo-customer-list.component';
import { EvoPartnerUpdateFormComponent } from './components/form/evo-customer-update-form.component';
import { EvoPartnerAddFormComponent } from './components/form/evo-customer-add-form.component';
import { EvoPlanPipe } from './pipe/evo-plan.pipe';
import { EvoPlanListComponent } from './components/list/evo-plan-list.component';
import { EvoPlanAddFormComponent } from './components/form/evo-plan-add-form.component';
import { EvoPlanUpdateFormComponent } from './components/form/evo-plan-update-form.component';
import { EvoTicketUserListComponent } from './components/list/evo-ticketuser-list.component';
import { EvoPartnerUserListComponent } from './components/list/evo-customer-user-list.component';
import { EvoPartnerUserAddFormComponent } from './components/form/evo-customer-user-add-form.component';
import { EvoPartnerUserUpdateFormComponent } from './components/form/evo-customer-user-update-form.component';
import { EvoPartnerUserPipe } from './pipe/evo-customer-user.pipe';
import { EvoTicketActivityListComponent } from './components/list/evo-ticket-activity-list.component';
import { EvoTicketActivityPipe } from './pipe/evo-ticket-activity.pipe';
import { AuthGuard } from './login/guard/auth.guard';
import { LoginModule } from './login/login.module';
import { SampleComponent } from 'app/main/sample/sample.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EvoPlanDetailComponent } from './components/information/evo-plandetail.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG } from 'ngx-swiper-wrapper';

import {NgxPaginationModule} from 'ngx-pagination';

import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { EvoPlanDetailMainSidebarComponent } from './components/information/evo-plan-detail-main-sidebar.component';
import { EvoPlanDetailEventSidebarComponent } from './components/information/evo-plan-detail-event-sidebar.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DashboardService } from './services/dashboard.service';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { FakeDbService } from './components/layout/fake-db.service';
import { EvoProfileSettingsComponent } from './components/settings/evo-profile-settings.component';
import { EvoModuleListComponent } from './components/list/evo-module-list.component';
import { EvoModuleAddFormComponent } from './components/form/evo-module-add-form.component';
import { EvoModuleUpdateFormComponent } from './components/form/evo-module-update-form.component';
import { EvoModulePipe } from './pipe/evo-module.pipe';
import { EvoContractListComponent } from './components/list/evo-contract-list.component';
import { EvoContractAddFormComponent } from './components/form/evo-contract-add-form.component';
import { EvoContractUpdateFormComponent } from './components/form/evo-contract-update-form.component';
import { EvoContractPipe } from './pipe/evo-contract.pipe';
import { EvoVacationListComponent } from './components/vacation/evo-vacation-list.component';
import { EvoExpenseComponent } from './components/information/evo-expense.component';
import { EvoExpensePipe } from './pipe/evo-expense.pipe';
import { EvoEffortPipe } from './pipe/evo-effort.pipe';
import { EvoGoalComponent } from './components/information/evo-goal.component';
import { EvoGoalPipe } from './pipe/evo-goal.pipe';
import { EvoGoalDetailPipe } from './pipe/evo-goal-detail.pipe';
import { EvoTransactionListComponent } from './components/list/evo-transaction-list.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { EvoSystemSettingsComponent } from './components/settings/evo-system-settings.component';
import { EvoTransactionPipe } from './pipe/evo-transaction.pipe';
import { EvoMailSettingPipe } from './pipe/evo-mail-setting.pipe';
import { EvoCompetenceListComponent } from './components/list/evo-competence-list.component';
import { EvoCompetencePipe } from './pipe/evo-competence.pipe';
import { EvoTodoComponent } from './components/information/evo-todo.component';
import { DragulaModule } from 'ng2-dragula';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { EvoTaskPipe } from './pipe/evo-task.pipe';

import { DndModule } from 'ngx-drag-drop'; // drag and drop module
import { CalendarModule } from 'primeng/calendar';
import { TabViewModule } from 'primeng/tabview';

import { MultiSelectModule } from 'primeng/multiselect';


import { EvoTicketListForCreator } from './components/list/evo-ticket-list-forcreator.component';
import { EvoVacationdayListComponent } from './components/list/evo-vacationday-list.component';
import { EvoTodoPipe } from './pipe/evo-todo.pipe';
import { EvoActivityInquiryListComponent } from './components/list/evo-activity-inquiry-list.component';

import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EvoServerControlComponent } from './components/list/evo-server-control.component';
import { FilterPipe } from './pipe/filter.pipe';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TreeModule } from 'primeng/tree';
import { EvoAnnualTermListComponent } from './components/list/evo-annual-term-list.component';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FilterTreePipe } from './pipe/filter-tree.pipe';
import { TreeSelectModule } from 'primeng/treeselect';
import { DialogModule } from 'primeng/dialog';



const routes = [
  {
    path: 'home',
    canActivateChild: [AuthGuard],
    children: [
      { path: '', component: EvoHomeComponent },
      { path: 'myprofile', component: EvoProfileSettingsComponent },
      { path: 'settings', component: EvoSystemSettingsComponent },

      { path: 'departments', component: EvoDepartmentListComponent, },
      { path: 'adddepartment', component: EvoDepartmentAddFormComponent },
      { path: 'updatedepartment/:Id', component: EvoDepartmentUpdateFormComponent },


      { path: 'projects', component: EvoProjectListComponent },
      { path: 'addproject', component: EvoProjectAddFormComponent },
      { path: 'updateproject/:Id', component: EvoProjectUpdateFormComponent },


      { path: 'operationclaims', component: EvoOperationClaimListComponent },
      { path: 'addoperationclaim', component: EvoOperationClaimAddFormComponent },
      { path: 'updateoperationclaim/:Id', component: EvoOperationClaimUpdateFormComponent },

      { path: 'users', component: EvoUserListComponent },
      { path: 'adduser', component: EvoUserAddFormComponent },
      { path: 'updateuser/:Id', component: EvoUserUpdateFormComponent },

      { path: 'partners', component: EvoPartnerListComponent },
      { path: 'addpartner', component: EvoPartnerAddFormComponent },
      { path: 'updatepartner/:Id', component: EvoPartnerUpdateFormComponent },

      { path: 'partnerusers/:Id', component: EvoPartnerUserListComponent },
      { path: 'addpartneruser', component: EvoPartnerUserAddFormComponent },
      { path: 'updatepartneruser/:Id', component: EvoPartnerUserUpdateFormComponent },

      { path: 'plans', component: EvoPlanListComponent },
      { path: 'addplan', component: EvoPlanAddFormComponent },
      { path: 'updateplan/:Id', component: EvoPlanUpdateFormComponent },
      { path: 'infoplan', component: EvoPlanDetailComponent },
      { path: 'vacations', component: EvoVacationListComponent },
      { path: 'vacationdays', component: EvoVacationdayListComponent },

      { path: 'useroperationclaims', component: EvoUserOperationClaimListComponent },
      { path: 'adduseroperationclaim', component: EvoUserOperationClaimAddFromComponent },
      { path: 'updateuseroperationclaim/:Id', component: EvoUserOperationClaimUpdateFormComponent },
      { path: 'infouseroperationclaim/:Id', component: EvoUseroperationclaimInfoFormComponent },

      { path: 'no-start', component: EvoTicketListComponent },
      { path: 'mytickets', component: EvoTicketListForCreator},
      { path: 'tickets', component: EvoTicketUserListComponent},
      { path: 'addticket', component: EvoTicketAddFormComponent, },
      { path: 'details/:TicketId', component: EvoTicketMessagesListComponent, data: { animation: 'messages' } },

      { path: 'ticketactivities', component: EvoTicketActivityListComponent },
      { path: 'activityinquiries', component: EvoActivityInquiryListComponent },

      { path: 'transactions', component: EvoTransactionListComponent },

      { path: 'modules', component: EvoModuleListComponent },
      { path: 'addmodule', component: EvoModuleAddFormComponent },
      { path: 'updatemodule/:Id', component: EvoModuleUpdateFormComponent },
      { path: 'competences', component: EvoCompetenceListComponent },

      { path: 'contracts', component: EvoContractListComponent },
      { path: 'addcontract', component: EvoContractAddFormComponent },
      { path: 'updatecontract/:Id', component: EvoContractUpdateFormComponent },

      { path: 'expenses', component: EvoExpenseComponent },
      { path: 'goals', component: EvoGoalComponent },
      { path: 'server-control', component: EvoServerControlComponent },
      { path: 'annualterms', component: EvoAnnualTermListComponent },

      {
        path: 'todo', component: EvoTodoComponent, data: { animation: 'todo' }
      },
    ]
  },

];

FullCalendarModule.registerPlugins([dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]);
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};


@NgModule({
  declarations: [
    EvoHomeComponent,
    EvoDepartmentListComponent,
    EvoDepartmentUpdateFormComponent,
    EvoDepartmentAddFormComponent,
    EvoProjectListComponent,
    EvoProjectAddFormComponent,
    EvoProjectUpdateFormComponent,
    EvoTicketListComponent,
    EvoTicketUserListComponent,
    EvoTicketAddFormComponent,
    EvoTicketMessagesListComponent,
    EvoUserOperationClaimAddFromComponent,
    EvoOperationClaimAddFormComponent,
    EvoActivityInquiryListComponent,
    EvoTicketPipe,
    EvoTodoPipe,
    EvoProjectPipe,
    EvoDepartmentPipe,
    EvoTicketuserPipe,
    EvoOperationClaimListComponent,
    EvoOperationClaimUpdateFormComponent,
    EvoOperationclaimPipe,
    EvoUserOperationClaimListComponent,
    EvoUserOperationClaimUpdateFormComponent,
    EvoUseroperationclaimInfoFormComponent,
    EvoUserOperationClaimPipe,
    EvoUserListComponent,
    EvoUserPipe,
    EvoUserAddFormComponent,
    EvoUserUpdateFormComponent,
    EvoPartnerPipe,
    EvoPartnerListComponent,
    EvoPartnerAddFormComponent,
    EvoPartnerUpdateFormComponent,
    EvoPlanPipe,
    EvoPlanListComponent,
    EvoPlanAddFormComponent,
    EvoPlanUpdateFormComponent,
    EvoPartnerUserListComponent,
    EvoPartnerUserAddFormComponent,
    EvoPartnerUserUpdateFormComponent,
    EvoPartnerUserPipe,
    EvoTicketActivityListComponent,
    EvoTicketActivityPipe,
    EvoTicketListForCreator,
    EvoPlanDetailComponent,
    EvoPlanDetailMainSidebarComponent,
    EvoPlanDetailEventSidebarComponent,
    EvoProfileSettingsComponent,
    EvoModuleListComponent,
    EvoModuleAddFormComponent,
    EvoModuleUpdateFormComponent,
    EvoModulePipe,
    EvoContractListComponent,
    EvoContractAddFormComponent,
    EvoContractUpdateFormComponent,
    EvoContractPipe,
    EvoVacationListComponent,
    EvoExpenseComponent,
    EvoExpensePipe,
    EvoEffortPipe,
    EvoGoalComponent,
    EvoGoalPipe,
    EvoGoalDetailPipe,
    EvoTransactionListComponent,
    EvoSystemSettingsComponent,
    EvoVacationdayListComponent,
    EvoTransactionPipe,
    EvoMailSettingPipe,
    EvoCompetenceListComponent,
    EvoServerControlComponent,
    EvoAnnualTermListComponent,
    FilterPipe,
    EvoCompetencePipe,
    FilterTreePipe,
    EvoTodoComponent,
    EvoTaskPipe,
  ],
  imports: [
    CommonModule,
    NgxDatatableModule,
    CoreThemeCustomizerModule,
    Ng2FlatpickrModule,
    NgSelectModule,
    FormsModule,
    CardSnippetModule,
    NgxPaginationModule,
    RouterModule.forChild(routes),
    NgbModule,
    CoreSidebarModule,
    ContextMenuModule,
    TreeModule,
    DropdownModule,
    MultiSelectModule,
    NgApexchartsModule,
    TreeSelectModule,
    DndModule,
    DragulaModule.forRoot(),
    PerfectScrollbarModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true
    }),

    FileUploadModule,
    FullCalendarModule,
    CoreCommonModule,
    ToastrModule,
    SwiperModule,
    CalendarModule,
    TabViewModule,
    TableModule,
    ButtonModule,
    InputSwitchModule,
    DialogModule,
    ConfirmDialogModule,
    ProgressSpinnerModule,
    QuillModule.forRoot({
      formats: [
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block',
        'formula'
        // 'image'
        // 'video'
      ],
    }
      
    ),
    NgMultiSelectDropDownModule.forRoot()

  ],
  exports: [
    EvoHomeComponent,
    EvoDepartmentListComponent,
    EvoDepartmentUpdateFormComponent,
    EvoDepartmentAddFormComponent,
    EvoProjectListComponent,
    EvoProjectAddFormComponent,
    EvoProjectUpdateFormComponent,
    EvoTicketListComponent,
    EvoTicketUserListComponent,
    EvoTicketAddFormComponent,
    EvoTicketMessagesListComponent,
    EvoUserOperationClaimAddFromComponent,
    EvoOperationClaimAddFormComponent,
    EvoPartnerUserListComponent,
    EvoPartnerUserAddFormComponent,
    EvoPartnerUserUpdateFormComponent,
    EvoSystemSettingsComponent,
    EvoActivityInquiryListComponent,
    EvoServerControlComponent

  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    DashboardService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ]

})
export class EvoModule { }
